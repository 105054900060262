<template>
  <v-card>
    <v-card-title class="primary--text">{{ title }}</v-card-title>
    <v-card-text class="pb-0">
      <v-form
        v-model="isFormValid"
        ref="formEditCodigoSSS"
        form="formEditCodigoSSS"
        id="formEditCodigoSSS"
        @submit.prevent="checkSameCodigoValue(codigo, arrayToCheckCodigos)"
      >
        <v-row>
          <v-col cols="12" md="12" class="pb-0">
            <v-text-field
              label="Nombre"
              dense
              outlined
              disabled
              v-model="nombreCampo"
            >
            </v-text-field>
            <v-text-field
              dense
              outlined
              v-model="codigo"
              onkeydown="return (event.keyCode !== 32)"
              label="Código SSS"
              :v-mask="tabToEdit != 'tipos-documento' ? '####' : []"
              :rules="
                codigo == '0'
                  ? []
                  : tabToEdit == 'tipos-documento'
                  ? rules.required.concat([rules.maxLength(codigo, 2)])
                  : rules.required.concat(rules.positiveNumber, [
                      tabToEdit == 'localidades'
                        ? rules.maxLength(codigo, 9)
                        : rules.maxLength(codigo, 4)
                    ])
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn outlined small @click="closeModal"> Cancelar </v-btn>
      <v-btn
        small
        type="submit"
        :disabled="!isFormValid"
        form="formEditCodigoSSS"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="saveEditCodigo()"
      @update:openConfirm="cancelarDialog"
      :submitBtn="'GUARDAR'"
      :cancelBtn="'EDITAR'"
    />
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  components: { ConfirmDialog },
  props: {
    idItemToEdit: { type: Number, required: true, default: null },
    fieldNameToEdit: { type: String, required: true, default: "" },
    codigoSSS: { type: [Number, String], required: false, default: null },
    tabToEdit: { type: String, required: true, default: "" },
    arrayToCheckCodigos: { type: Array }
  },
  data() {
    return {
      title: "Editar código SSS",
      rules: rules,
      isFormValid: null,
      nombreCampo: null,
      codigo: null,
      textConfirmDialog: "",
      openConfirmDialog: false
    };
  },
  created() {
    this.setEditForm();
  },

  methods: {
    ...mapActions({
      saveNacionalidades: "configAfiliaciones/saveNacionalidades",
      postEstadosCiviles: "configAfiliaciones/postEstadosCiviles",
      postTipoDocumento: "configAfiliaciones/postTipoDocumento",
      saveProvincias: "configAfiliaciones/saveProvincias",
      saveLocalidades: "configAfiliaciones/saveLocalidades",
      setAlert: "user/setAlert"
    }),
    setEditForm() {
      this.codigo = this.codigoSSS;
      this.nombreCampo = this.fieldNameToEdit;
    },
    async checkSameCodigoValue(codigo, array) {
      const sameCodigo = array.find(
        x =>
          x.sssCodigo == codigo &&
          this.idItemToEdit !=
            (x.tdId || x.nacId || x.estcivilId || x.provId || x.locId)
      );
      
      if (sameCodigo != undefined) {
        this.openConfirmDialog = true;
        switch (this.tabToEdit) {
          case "tipos-documento":
            this.textConfirmDialog = `El registro "${sameCodigo.tdNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
            break;
          case "nacionalidades":
            this.textConfirmDialog = `El registro "${sameCodigo.nacNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
            break;
          case "estados-civiles":
            this.textConfirmDialog = `El registro "${sameCodigo.estcivilNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
            break;
          case "localidades":
            this.textConfirmDialog = `El registro "${sameCodigo.locNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
            break;
          case "provincias":
            this.textConfirmDialog = `El registro "${sameCodigo.provNombre}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
            break;

          default:
            break;
        }
      } else {
        this.saveEditCodigo();
      }
    },
    async saveEditCodigo() {
      switch (this.tabToEdit) {
        case "tipos-documento":
          const tipoDoc = {
            tdId: this.idItemToEdit,
            tdNombre: this.nombreCampo.toUpperCase(),
            sssCodigo: this.codigo
          };
          const responseDoc = await this.postTipoDocumento(tipoDoc);
          if (responseDoc.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeAndReload();
          }
          break;
        case "nacionalidades":
          const nacionalidad = {
            nacId: this.idItemToEdit,
            nacNombre: this.nombreCampo,
            sssCodigo: this.codigo
          };
          const responseNac = await this.saveNacionalidades(nacionalidad);
          if (responseNac.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeAndReload();
          }
          break;
        case "estados-civiles":
          const estCivil = {
            estcivilId: this.idItemToEdit,
            estcivilNombre: this.nombreCampo,
            sssCodigo: this.codigo
          };
          const responseEstCivil = await this.postEstadosCiviles(estCivil);
          if (responseEstCivil.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeAndReload();
          }
          break;
        case "localidades":
          const localidad = {
            locId: this.idItemToEdit,
            locNombre: this.nombreCampo,
            sssCodigo: this.codigo
          };
          const responseLocalidad = await this.saveLocalidades(localidad);
          if (responseLocalidad.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeAndReload();
          }
          break;
        case "provincias":
          const provincia = {
            provId: this.idItemToEdit,
            provNombre: this.nombreCampo,
            sssCodigo: this.codigo
          };
          const responseProvincia = await this.saveProvincias(provincia);
          if (responseProvincia.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.closeAndReload();
          }
          break;

        default:
          break;
      }
    },

    closeAndReload() {
      this.$emit("closeModalsAndSetTables");
    },
    closeModal() {
      this.$emit("toggleModalEditCodigoSSS");
    },
    cancelarDialog() {
      this.textConfirmDialog = "";
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
