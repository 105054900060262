var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"formEditCodigoSSS",attrs:{"form":"formEditCodigoSSS","id":"formEditCodigoSSS"},on:{"submit":function($event){$event.preventDefault();return _vm.checkSameCodigoValue(_vm.codigo, _vm.arrayToCheckCodigos)}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","dense":"","outlined":"","disabled":""},model:{value:(_vm.nombreCampo),callback:function ($$v) {_vm.nombreCampo=$$v},expression:"nombreCampo"}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","onkeydown":"return (event.keyCode !== 32)","label":"Código SSS","v-mask":_vm.tabToEdit != 'tipos-documento' ? '####' : [],"rules":_vm.codigo == '0'
                ? []
                : _vm.tabToEdit == 'tipos-documento'
                ? _vm.rules.required.concat([_vm.rules.maxLength(_vm.codigo, 2)])
                : _vm.rules.required.concat(_vm.rules.positiveNumber, [
                    _vm.tabToEdit == 'localidades'
                      ? _vm.rules.maxLength(_vm.codigo, 9)
                      : _vm.rules.maxLength(_vm.codigo, 4)
                  ])},model:{value:(_vm.codigo),callback:function ($$v) {_vm.codigo=$$v},expression:"codigo"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"small":"","type":"submit","disabled":!_vm.isFormValid,"form":"formEditCodigoSSS","color":"primary"}},[_vm._v(" Guardar ")])],1),_c('ConfirmDialog',{attrs:{"text":_vm.textConfirmDialog,"openConfirm":_vm.openConfirmDialog,"submitBtn":'GUARDAR',"cancelBtn":'EDITAR'},on:{"update:openConfirm":[function($event){_vm.openConfirmDialog=$event},_vm.cancelarDialog],"update:open-confirm":function($event){_vm.openConfirmDialog=$event},"onConfirm":function($event){return _vm.saveEditCodigo()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }