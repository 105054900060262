<template>
  <v-card>
    <v-card-title class="primary--text">{{ title }}</v-card-title>
    <v-form
      v-model="isFormValid"
      ref="form-edit-tipos-beneficiarios"
      form="form-edit-tipos-beneficiarios"
      id="form-edit-tipos-beneficiarios"
      @submit.prevent="checkSameCodigoValue(tipoBenCod, arrayToCheckCodigos)"
    >
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              label="Tipo de beneficiario"
              dense
              outlined
              disabled
              v-model="tipoBenNom"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              dense
              outlined
              onkeydown="return (event.keyCode !== 32)"
              v-model="tipoBenCod"
              label="Código SSS"
              :rules="rules.required.concat([rules.maxLength(tipoBenCod, 2)])"
            >
            </v-text-field>
              <!-- @blur="
                forzarCuitExpSss == null || forzarCuitExpSss.length === 0
                  ? [(isFormValid = true)]
                  : validarCUIL(forzarCuitExpSss)
              " -->
          </v-col>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <v-switch
              class="py-0"
              v-model="cuilTitularEnCuitempleador"
              label="Forzar CUIL del titular en campo CUIT del empleador"
              dense
              outlined
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="12" md="12" class="py-0">
            <v-switch
              class="py-0"
              v-model="tipoBenNoIncluirPadron"
              label="Excluir del padrón SSS"
              dense
              outlined
            ></v-switch>
          </v-col>
          <v-col cols="12" md="12" class="pb-0">
            <v-text-field
              dense
              outlined
              v-model="forzarCuitExpSss"
              v-mask="'##-########-#'"
              label="CUIT a forzar en el padrón mensual a la SSS"
              @blur="
                forzarCuitExpSss !== null || forzarCuitExpSss == ''
                  ? validarCUIL(forzarCuitExpSss)
                  : []
              "
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn outlined small @click="closeModal"> Cancelar </v-btn>
        <v-btn
          small
          type="submit"
          :disabled="!isFormValid"
          form="form-edit-tipos-beneficiarios"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-form>
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="saveEditTipoBeneficiario()"
      @update:openConfirm="cancelarDialog"
      :submitBtn="'GUARDAR'"
      :cancelBtn="'EDITAR'"
    />
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import validateCUIL from "@/utils/helpers/validateCUIL";
import { mask } from "vue-the-mask";

export default {
  components: { ConfirmDialog },
  directives: { mask },

  props: {
    tipoBeneficiarioObject: { type: Object, required: false, default: null },
    arrayToCheckCodigos: { type: Array }
  },
  data() {
    return {
      title: "Editar tipo beneficiario",
      rules: rules,
      isFormValid: null,
      tipoBenNom: null,
      tipoBenCod: null,
      tipoBenId: null,
      tipoBenSituRevi: null,
      tipoBenNoIncluirPadron: false,
      tipoBenEsDesempleo: false,
      cuilTitularEnCuitempleador: false,
      forzarCuitExpSss: null,
      textConfirmDialog: "",
      openConfirmDialog: false
    };
  },
  created() {
    if (this.tipoBeneficiarioObject.tipoBenId !== null);
    {
      this.setTipoBeneficiario();
    }
  },
  methods: {
    ...mapActions({
      saveTiposBeneficiarios: "configAfiliaciones/saveTiposBeneficiarios",
      setAlert: "user/setAlert"
    }),
    setTipoBeneficiario() {
      this.tipoBenNom = this.tipoBeneficiarioObject.tipoBenNom;
      this.tipoBenCod = this.tipoBeneficiarioObject.tipoBenCod;
      this.tipoBenId = this.tipoBeneficiarioObject.tipoBenId;
      this.tipoBenSituRevi = this.tipoBeneficiarioObject.tipoBenSituRevi;
      this.tipoBenNoIncluirPadron = this.tipoBeneficiarioObject.tipoBenNoIncluirPadron;
      this.tipoBenEsDesempleo = this.tipoBeneficiarioObject.tipoBenEsDesempleo;
      this.cuilTitularEnCuitempleador = this.tipoBeneficiarioObject.cuilTitularEnCuitempleador;
      this.forzarCuitExpSss = this.tipoBeneficiarioObject.forzarCuitExpSss;
    },
    async checkSameCodigoValue(codigo, array) {
      const sameCodigo = array.find(
        x => x.tipoBenCod == codigo && this.tipoBenId != x.tipoBenId
      );
      if (sameCodigo != undefined) {
        this.openConfirmDialog = true;
        this.textConfirmDialog = `El registro "${sameCodigo.tipoBenNom}" posee el mismo código SSS, ¿Desea guardar de todas formas?`;
      } else {
        this.saveEditTipoBeneficiario();
      }
    },
    async saveEditTipoBeneficiario() {
      const tipoBeneficiario = {
        tipoBenId: this.tipoBenId,
        tipoBenNom: this.tipoBenNom,
        tipoBenCod: this.tipoBenCod,
        tipoBenEsDesempleo: this.tipoBenEsDesempleo,
        tipoBenNoIncluirPadron: this.tipoBenNoIncluirPadron,
        tipoBenSituRevi: this.tipoBenSituRevi,
        cuilTitularEnCuitempleador: this.cuilTitularEnCuitempleador,
        forzarCuitExpSss: this.forzarCuitExpSss
          ? this.forzarCuitExpSss.replaceAll("-", "")
          : this.forzarCuitExpSss
      };
      const response = await this.saveTiposBeneficiarios(tipoBeneficiario);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeAndReload();
      }
    },
    validarCUIL(cuil) {
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        this.setAlert({
          type: "warning",
          message: "El CUIL ingresado es incorrecto."
        });
        this.isFormValid = false;
      } else if (this.tipoBenCod !== null) {
        this.isFormValid = true;
      }
    },
    closeAndReload() {
      this.$emit("closeModalsAndSetTables");
    },
    closeModal() {
      this.$emit("toggleModalEditTiposBeneficiarios");
    },
    cancelarDialog() {
      this.textConfirmDialog = "";
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
::v-deep .v-input__slot {
  cursor: context-menu !important;
}

::v-deep label {
  margin-bottom: 0;
}
</style>
