<template>
  <v-card>
    <v-container>
      <v-card-title class="pl-1 primary--text">Otros parámetros</v-card-title>
      <v-container>
        <v-row>
          <v-form
            v-model="isFormValid"
            ref="form"
            form="form"
            id="form"
            @submit.prevent="saveConfiguracionParametros()"
          >
            <v-row>
              <!-- Incluir grupo familiar -->
              <v-col cols="6" md="8" sm="12">
                <v-text-field
                  v-model="diasCant"
                  outlined
                  dense
                  label="Días reincorporación"
                  v-mask="'##'"
                  suffix="días"
                  :rules="
                    diasCant === '' || diasCant === null
                      ? ['Campo requerido']
                      : rules.requiredAcceptZero.concat([
                          rules.minMaxNumber(diasCant, 0, 99)
                        ])
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="1" md="1" sm="1" class="py-0 px-0">
                <v-tooltip right max-width="50rem">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="1.4rem"
                      class="pt-4"
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                    >
                      {{ infoIcon }}</v-icon
                    >
                  </template>
                  <h6>
                    Cantidad de días entre Baja y Reincorporación para informar
                    nueva fecha de alta en OS
                  </h6>
                  <p>
                    En esta opción se configura la cantidad de días que deben
                    transcurrir, como mínimo, entre la fecha asignada al
                    movimiento de Baja de un afiliado, y la fecha en la que el
                    mismo se reincorpora a la cobertura de la Obra Social. En
                    caso de superarse la cantidad de días configurados, se
                    informará la fecha de esa reincorporación como fecha de Alta
                    en la Obra Social, en la exportación del Padrón SSS.
                  </p>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModalParametro()"> Cerrar </v-btn>
        </v-card-actions>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import rules from "@/utils/helpers/rules";
export default {
  name: "ParametrosGralesPadron",
  directives: { mask },
  components: {
    GoBackBtn
  },

  data: () => ({
    isFormValid: false,
    diasCant: null,
    infoIcon: enums.icons.SNB_INFO,
    rules: rules
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setConfigParametro();
  },
  methods: {
    ...mapActions({
      getParametrosConfig: "configAfiliaciones/getParametrosConfig",
      postParametrosConfig: "configAfiliaciones/postParametrosConfig",
      setAlert: "user/setAlert"
    }),
    async saveConfiguracionParametros() {
      const data = {
        cantidadDiasReincorBajaParaAltaSSS: this.diasCant
      };
      const response = await this.postParametrosConfig(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$router.push({ name: "PadronMensualSSS" });
      }
    },
    async setConfigParametro() {
      const response = await this.getParametrosConfig();
      this.diasCant = response.cantidadDiasReincorBajaParaAltaSSS;
    },
    closeModalParametro() {
      this.$emit("closeModalParametros");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 0;
  margin-left: 2px;
}
</style>
