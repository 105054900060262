var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v("Otros parámetros")]),_c('v-container',[_c('v-row',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfiguracionParametros()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"8","sm":"12"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"outlined":"","dense":"","label":"Días reincorporación","suffix":"días","rules":_vm.diasCant === '' || _vm.diasCant === null
                    ? ['Campo requerido']
                    : _vm.rules.requiredAcceptZero.concat([
                        _vm.rules.minMaxNumber(_vm.diasCant, 0, 99)
                      ])},model:{value:(_vm.diasCant),callback:function ($$v) {_vm.diasCant=$$v},expression:"diasCant"}})],1),_c('v-col',{staticClass:"py-0 px-0",attrs:{"cols":"1","md":"1","sm":"1"}},[_c('v-tooltip',{attrs:{"right":"","max-width":"50rem"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pt-4",attrs:{"size":"1.4rem","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.infoIcon))])]}}])},[_c('h6',[_vm._v(" Cantidad de días entre Baja y Reincorporación para informar nueva fecha de alta en OS ")]),_c('p',[_vm._v(" En esta opción se configura la cantidad de días que deben transcurrir, como mínimo, entre la fecha asignada al movimiento de Baja de un afiliado, y la fecha en la que el mismo se reincorpora a la cobertura de la Obra Social. En caso de superarse la cantidad de días configurados, se informará la fecha de esa reincorporación como fecha de Alta en la Obra Social, en la exportación del Padrón SSS. ")])])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModalParametro()}}},[_vm._v(" Cerrar ")])],1),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }